/* Light theme variables */
:root {
  --bg-color: #f8fafc;
  --text-color: #1e293b;
  --primary-color: #0ea5e9;
  --primary-hover: #0284c7;
  --card-bg: #ffffff;
  --bid-bg: rgba(34, 197, 94, 0.1);
  --ask-bg: rgba(239, 68, 68, 0.1);
  --select-bg: #ffffff;
  --select-border: #e2e8f0;
  --button-text: #ffffff;
  --card-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --bid-color: #16a34a;
  --ask-color: #dc2626;
}

/* Dark theme variables */
[data-theme='dark'], .dark {
  --bg-color: #0f172a;
  --text-color: #f1f5f9;
  --primary-color: #0ea5e9;
  --primary-hover: #0284c7;
  --card-bg: #1e293b;
  --bid-bg: rgba(34, 197, 94, 0.2);
  --ask-bg: rgba(239, 68, 68, 0.2);
  --select-bg: #1e293b;
  --select-border: #334155;
  --button-text: #ffffff;
  --card-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.3), 0 2px 4px -2px rgb(0 0 0 / 0.3);
  --bid-color: #22c55e;
  --ask-color: #ef4444;
}

body {
  margin: 0;
  background-color: var(--bg-color);
  transition: all 0.3s ease;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.App {
  text-align: center;
  background-color: var(--bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.App-header {
  padding: 32px;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.controls {
  display: flex;
  gap: 32px;
  margin-top: 40px;
  justify-content: center;
  flex-wrap: wrap;
}

select {
  background-color: var(--select-bg);
  color: var(--text-color);
  border: 1px solid var(--select-border);
  border-radius: 12px;
  padding: 16px 32px;
  font-size: 18px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
  background-size: 12px auto;
  min-width: 240px;
  box-shadow: var(--card-shadow);
}

button:not(.theme-toggle) {
  background-color: var(--primary-color);
  color: var(--button-text);
  border-radius: 12px;
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: var(--card-shadow);
  min-width: 200px;
}

button:not(.theme-toggle):hover {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
  box-shadow: 0 8px 12px -3px rgb(0 0 0 / 0.2);
}

.market-display {
  margin: 40px auto;
  padding: 32px;
  background: var(--card-bg);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  min-width: 300px;
  max-width: 800px;
  box-shadow: var(--card-shadow);
}

.price-column {
  text-align: center;
  padding: 24px 40px;
  border-radius: 16px;
  min-width: 200px;
  transition: all 0.3s ease;
}

.bid-column {
  background: var(--bid-bg);
  color: var(--bid-color);
}

.ask-column {
  background: var(--ask-bg);
  color: var(--ask-color);
}

.price-column h3 {
  font-size: 1.25rem;
  margin: 0 0 16px 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.price {
  font-size: 2.5em;
  font-weight: 700;
  margin: 16px 0;
  letter-spacing: -0.02em;
}

.quantity {
  font-size: 1.1em;
  opacity: 0.8;
  margin-top: 8px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.app-logo {
  font-size: 3em;
  margin-right: 16px;
  background: linear-gradient(135deg, var(--primary-color), #38bdf8);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: pulse 2s ease-in-out infinite;
}

.logo-text {
  font-size: 3em;
  font-weight: 700;
  background: linear-gradient(135deg, var(--primary-color), #38bdf8);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0 20px;
  letter-spacing: -0.02em;
}

.theme-toggle {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  padding: 8px;
  margin-left: 20px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0.9;
  background-color: var(--card-bg);
  box-shadow: var(--card-shadow);
}

.theme-toggle:hover {
  opacity: 1;
  transform: translateY(-2px);
}

@keyframes flash-animation {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.05); opacity: 0.9; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Responsive styles */
@media (max-width: 768px) {
  .App-header {
    padding: 16px;
  }

  .controls {
    gap: 16px;
    margin-top: 20px;
  }

  select {
    padding: 12px 24px;
    font-size: 16px;
    min-width: 200px;
  }

  button:not(.theme-toggle) {
    padding: 12px 24px;
    font-size: 16px;
    min-width: 160px;
  }

  .market-display {
    padding: 24px;
    gap: 20px;
    min-width: 280px;
  }

  .price-column {
    padding: 16px 20px;
  }

  .app-logo, .logo-text {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .controls {
    flex-direction: column;
    gap: 12px;
  }

  select {
    padding: 10px 20px;
    font-size: 14px;
    min-width: 180px;
  }

  button:not(.theme-toggle) {
    padding: 10px 20px;
    font-size: 14px;
    min-width: 140px;
  }

  .market-display {
    padding: 16px;
    gap: 16px;
    min-width: 260px;
  }

  .price-column {
    padding: 12px 16px;
  }

  .app-logo, .logo-text {
    font-size: 1.5em;
  }
}